import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Popover } from "reactstrap";
import { withRouter } from "react-router";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import NotificationPanel from "../notification/notification-save-query-name";
import { condenseForm } from "../../../controller/redux/analysisFormActions";
import { removeBackgroundProcess, getProcessByToken, renameBackgroundProcess } from "../../../controller/redux/backgroundProcessAction";

import { ReactComponent as Starting } from "../img/starting.svg";
import { ReactComponent as MoreOver } from "../img/more-over.svg";
import { ReactComponent as Done } from "../img/done.svg";
import { ReactComponent as Error } from "../img/error.svg";
import { ReactComponent as Running } from "../img/running.svg";
import { ReactComponent as More } from "../img/more.svg";
import { ReactComponent as Cross } from "../img/x.svg";
import { ReactComponent as Edit } from "../img/edit.svg";
import { ReactComponent as EditWhite } from "../img/edit-white.svg";

import "./process.scss";

export class Process extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDialogueSave: false,
            anchorEl: null,
            isOver: false,
            isOverMore: false,
            isVisible: true,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.removeProcess = this.removeProcess.bind(this);
    }

    handleClickEvent = (event) => {
        this.setState({ anchorEl: { left: event.clientX, top: event.clientY } });
    };

    removeProcess = async (value) => {
        this.setState({ isDialogueSave: false, anchorEl: null, isVisible: false });
        this.props.isRemovingItem(value);
    };

    handleClick = async (event, value) => {
        if (event === "show") {
            await this.props.getProcessByToken(value.workItemId);
            const path = value.linkId ? `/query/${value.linkId}` : "/analysis";
            this.props.history.push({
                pathname: path,
                state: { isQueryFromApp: true },
            });
            this.props.condenseForm();
            this.setState({ isDialogueSave: false, anchorEl: null });
        }
        if (event === "edit") {
            this.setState({ isDialogueSave: true, anchorEl: null });
        }
        if (event === "more") {
            this.setState({ isDialogueMenu: true });
        }
        if (event === "cancel") {
            this.setState({ isDialogueSave: false });
        } else if (event === "save") {
            await this.props.isRenamingItem(value.workItemId, value.newName);
            this.setState({ isDialogueSave: false });
        }
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    closePopover = () => {
        this.setState({ isDialogueSave: false });
    };

    render() {
        const { isDialogueSave, anchorEl, isOver, isOverMore } = this.state;
        const { object, index } = this.props;
        const status = object && object.status ? object.status : null;
        const isCurrent = this.props.currentProcess && this.props.currentProcess.workItemId === object.workItemId;
        const backgroundColorValue = isCurrent || isOver ? "#080725b8" : "#071c36";
        return (
            <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                        id={`menu-${index}`}
                        className="background-process-rectangle"
                        onMouseEnter={() => this.setState({ isOver: true })}
                        onClick={(e) => {
                            this.handleClick("show", object);
                        }}
                        onMouseLeave={() => this.setState({ isOver: false })}
                        style={{ backgroundColor: backgroundColorValue }}
                    >
                        <div className="background-process-running">
                            {status === "COMPLETE" ? <Done /> : null}
                            {status === "PROCESSING" ? <Running /> : null}
                            {status === "ERROR" ? <Error /> : null}
                            {status === "STARTING" ? <Starting /> : null}
                            {status === "INITIATE" ? <Starting /> : null}
                            {status === "MODIFYING" ? (
                                <div style={{ marginLeft: "5px" }}>
                                    <EditWhite />{" "}
                                </div>
                            ) : null}
                            {status == null ? <Starting /> : null}
                        </div>
                        <div className="background-process-Name">{(object && object.title) || "NO NAME"}</div>
                    </div>

                    <div
                        onClick={this.handleClickEvent}
                        style={{ zIndex: 10, marginLeft: "-15px", width: "10px" }}
                        className="background-process-more"
                        onMouseEnter={() => this.setState({ isOverMore: true })}
                        onMouseLeave={() => this.setState({ isOverMore: false })}
                    >
                        {isOverMore ? <MoreOver className="svg" /> : <More className="svg" />}
                    </div>
                    <Menu
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                        className="popover-menu-absolute"
                        anchorPosition={anchorEl}
                        anchorReference="anchorPosition"
                    >
                        <MenuItem onClick={() => this.removeProcess(object)}>
                            <Cross />
                            Close
                        </MenuItem>
                        <MenuItem onClick={() => this.handleClick("edit", object)}>
                            <Edit />
                            Rename
                        </MenuItem>
                    </Menu>
                    <Popover
                        placement="top-end"
                        target={`menu-${index}`}
                        isOpen={isDialogueSave}
                        toggle={this.closePopover}
                        boundariesElement="window"
                    >
                        <NotificationPanel handleClick={this.handleClick} object={object}></NotificationPanel>
                    </Popover>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentProcess: state.backgroundProcesses.currentProcess,
        processes: state.backgroundProcesses.processes,
    };
};

const mapDispatchToProps = {
    removeBackgroundProcess,
    getProcessByToken,
    renameBackgroundProcess,
    condenseForm,
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Process);
