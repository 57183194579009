import moment from "moment";
import {CRITICAL_SEVERITY_VALUE} from "../../integrations/view/modals/syslog/const";

const generateTimeStamps = (daysApart) => {
    let to = moment().endOf("day").valueOf();
    let from = moment(to).subtract(daysApart, 'days').startOf("day").valueOf();

    return [from, to];
}

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const vcaUrlBuilder = (baseUrl, from, to, limit, isCritical, sortKey) => {
    let url = `${baseUrl}/?from=${from}&to=${to}`;
    if (limit) {
        url += `&limit=${limit}`
    }
    if (isCritical) {
        url += `&isCritical=${isCritical}`
    }

    if (sortKey) {
        url += `&sortKey=${sortKey}`
    }
    return url;
}

const calculateSeverity = (severities, severity, isCritical) => {
    if (isCritical) {
        return CRITICAL_SEVERITY_VALUE;
    }
    for (let [key, icon] of Object.entries(severities)) {
        if(severity >= icon.min && severity <= icon.max){
            return key;
        }
    }
    return 'default';
}

export {generateTimeStamps, descendingComparator, stableSort, getComparator, vcaUrlBuilder, calculateSeverity}
