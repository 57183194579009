import React, { Component } from 'react';
import {
	LoadingBar,
	NoData,
	NoTimeSegment,
	RunNewAnalyse,
	ErrorAnalyse,
	AnalysisNoData,
} from '../statement/statementBox';

import GraphTime from '../../../../../shared/components/graph/graphs/series/graph';
import { GraphNumber } from '../../../../../shared/components/graph/graphs/numbers/graphNumber';
import _ from 'lodash';
import { connect } from 'react-redux';
import { manipulateAnalyzedData } from '../../../controller/utils/common';

class CurrentProcess extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { currentProcess, isPerSecond } = this.props;
		const { isLoading } = this.state;
		let currentGraph = null; let currentTimeWindow = null; let selectedDataType = null;
		let currentxDomain = null; let titleGraph = null;
		if (!_.isEmpty(currentProcess)) {
			if (currentProcess.status == 'COMPLETE') {
				if (!_.isEmpty(_.has(currentProcess, 'analysisResponse') ? currentProcess : {})) {
					const { xDomain, dataTypeOptions, timeWindow } = currentProcess.originalQuery;
					titleGraph = dataTypeOptions.label;
					currentxDomain = xDomain;
					currentTimeWindow = timeWindow;
					selectedDataType = dataTypeOptions;
					if (_.has(currentProcess.analysisResponse, 'analyzedData')
                        && !_.isEmpty(currentProcess.analysisResponse)
                        && !_.isEmpty(currentProcess.analysisResponse.analyzedData)
                        && currentProcess.analysisResponse.analyzedData[selectedDataType.accessor]) {
						currentGraph = currentProcess.analysisResponse.analyzedData[selectedDataType.accessor];
					}

					if (isPerSecond == true
                        && !_.isEmpty(currentProcess.analysisResponse)
                        && !_.isEmpty(currentProcess.analysisResponse.analyzedData)
                        && currentProcess.analysisResponse.analyzedData[selectedDataType.accessor]) {
						currentGraph = manipulateAnalyzedData(currentProcess.analysisResponse.analyzedData, selectedDataType.accessor, currentTimeWindow)[selectedDataType.accessor];
					}
				}
			}
		}

		return (
			<>

				<div className="graph-view">

					{ _.isEmpty(currentProcess) ? <RunNewAnalyse /> : null }

					{ !_.isEmpty(currentProcess) && _.has(currentProcess, 'status')
                        && (currentProcess.status == 'PROCESSING' || currentProcess.status == 'STARTING' || !currentProcess.status) ? <LoadingBar /> : !_.isEmpty(currentProcess) && _.has(currentProcess, 'status')
                            && (currentProcess.status == 'COMPLETE') ? !_.isEmpty(currentGraph)
								? selectedDataType.queryType == 'timeUnit'

									? <GraphTime
										label={ <span>{ titleGraph } { isPerSecond ? '- Per Second' : '' }</span> }
										yTitle={ titleGraph }
										data={ _.cloneDeep(currentGraph) }
										xDomain={ currentxDomain }
										timeUnits={ currentTimeWindow }
									/>
									: <GraphNumber
										label={ <span>{ titleGraph } { isPerSecond ? '- Per Second' : '' }</span> }
										yTitle={ titleGraph }
										data={ _.cloneDeep(currentGraph) }
										xDomain={ currentxDomain }
										timeUnits={ currentTimeWindow }
									/>
								: null : null }
					{ _.isEmpty(currentGraph) && !_.isEmpty(currentProcess) && currentProcess.status == 'COMPLETE' ? <AnalysisNoData /> : null }
				</div>

			</>

		);
	}
}
/**
 *
 *                 <div style={ { color: 'white', backgroundColor: 'green', fontSize: '20px' } }>
                    { JSON.stringify(_.pick(this.props.currentProcess, 'status')) }

                </div>
 */
const mapStateToProps = state => ({
	currentProcess: state.backgroundProcesses.currentProcess,
	isPerSecond: state.backgroundProcesses.isPerSecond,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentProcess);
