import { Divider } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import CustomTooltip from '../../../../../shared/components/Tooltip/CustomTooltip';
import StyledToggle from '../../../../../shared/components/form/Toggle/StyledToggle';
import useStyles from './actionFields.css';

const EDIT_DISABLED_TEXT = 'This rule cannot be edited';
const DELETE_DISABLED_TEXT = 'This rule cannot be deleted';

const ActionsField = ({ rule, isEnabled, onEdit, onDelete, onToggle }) => {
	const { isEditable, isDeletable } = rule;
	const classes = useStyles({ isEnabled, isEditable, isDeletable });
	const tooltipText = () => {
		if (!isEditable) {
			return 'Not Editable';
		}

		return isEnabled ? 'Disable' : 'Enable';
	};

	return (
		<div className={classes.actionsWrapper}>
			<CustomTooltip
				title={tooltipText()}
				PopperProps={{ style: { marginTop: 0 } }}
			>
				<div className={classes.enabledSwitch}>
					<StyledToggle
						checked={isEnabled}
						onChange={() => onToggle(rule)}
						name="allowToggle"
						disabled={!isEditable}
					/>
				</div>
			</CustomTooltip>
			<Divider
				orientation="vertical"
				className={classes.divider}
				flexItem/>
			<CustomTooltip title={isEditable ? 'Edit' : EDIT_DISABLED_TEXT}>
				<span>	{/* span is used to show tooltip when button is disabled */}
					<IconButton onClick={() => onEdit(rule)} disabled={!isEditable}>
						<Edit className={classes.actionIcon} fontSize="small"/>
					</IconButton>
				</span>
			</CustomTooltip>
			<Divider
				orientation="vertical"
				className={classes.divider}
				flexItem
			/>
			<CustomTooltip title={isDeletable ? 'Delete' : DELETE_DISABLED_TEXT}>
				<span>
					<IconButton onClick={() => onDelete(rule)} disabled={!isDeletable}>
						<Delete className={classes.actionIcon} fontSize="small"/>
					</IconButton>
				</span>
			</CustomTooltip>
		</div>
	);
};

ActionsField.propTypes = {
	isEnabled: PropTypes.bool,
	onDelete: PropTypes.func,
	onEdit: PropTypes.func,
	onToggle: PropTypes.func,
	rule: PropTypes.object,
};

export default ActionsField;
